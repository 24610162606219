<template>
    <div class="Dashboard_I">
        <div class="headNav">
            <div class="headNavLeft">
                <div class="left"><img :src="Profile.full_avatar" alt=""></div>
                <div class="right">
                    <div>{{name}}</div>
                    <div>早上好，今天星期二, 天气晴</div>
                </div>
            </div>
            <div class="headNavRight">
                <div>放假倒计时</div>
                <div>距离放假还剩</div>
            </div>
        </div>

        <!-- 导航栏 -->
        <div class="saleNav">
            <div class="navs">
                <div class="navsTop">
                    <div class="navsTopLeft">
                        <div>今日业绩</div>
                        <div>¥ {{today.order_num}}</div>
                    </div>
                    <div class="navsTopRight">
                        <img src="../assets/img/Q1.png" alt="">
                    </div>
                </div>
                <div class="navsBtn">总业绩额 ￥{{total.order_amount}}</div>
            </div>
            <div class="navs">
                <div class="navsTop">
                    <div class="navsTopLeft">
                        <div>今日销售订单数</div>
                        <div>¥ {{today.order_amount}}</div>
                    </div>
                    <div class="navsTopRight">
                        <img src="../assets/img/Q2.png" alt="">
                    </div>
                </div>
                <div class="navsBtn">总业绩额 ￥{{total.order_amount}}</div>
            </div>
            <div class="navs">
                <div class="navsTop">
                    <div class="navsTopLeft">
                        <div>今日新增销售线索</div>
                        <div>¥ {{today.leads_num}}</div>
                    </div>
                    <div class="navsTopRight">
                        <img src="../assets/img/Q3.png" alt="">
                    </div>
                </div>
                <div class="navsBtn">总业绩额 ￥{{today.leads_num}}</div>
            </div>
            <div class="navs">
                <div class="navsTop">
                    <div class="navsTopLeft">
                        <div>新增意向客户</div>
                        <div>¥ {{today.customer_num}}</div>
                    </div>
                    <div class="navsTopRight">
                        <img src="../assets/img/Q4.png" alt="">
                    </div>
                </div>
                <div class="navsBtn">总业绩额 ￥{{today.leads_num}}</div>
            </div>
        </div>

        <!-- 公告栏 -->
        <div class="NoticeNav">
            <div class="NoticeNavLeft">
                <div id="myChart1"></div>
            </div>
            <div class="NoticeNavRight">
                <div class="NoticeNavRightTop">
                    <div>公告</div>
                    <div @click="liClick(15)">全部公告</div>
                </div>
                <div class="NoticeNavRightBtn">
                    <div v-for="item in noticelist" :key="item.id">
                        <span class="span"></span>
                        <span>{{item.title}}</span>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- 功能入口 -->
        <div class="FunctionNav">
            <div class="FunctionNavLeft">
                <div class="FunctionNavOne">常用功能入口</div>
                <div class="FunctionNavLi">
                    <ul>
                        <li @click="liClick(1)"><img src="../assets/img/W1.png" alt=""><div>找客户</div></li>
                        <li @click="liClick(2)"><img src="../assets/img/W2.png" alt=""><div>添加员工</div></li>
                        <li @click="liClick(3)"><img src="../assets/img/W3.png" alt=""><div>查看订单</div></li>
                        <li @click="liClick(4)"><img src="../assets/img/W4.png" alt=""><div>网盘</div></li>
                        <li @click="liClick(5)"><img src="../assets/img/W5.png" alt=""><div>查售后</div></li>
                        <li @click="liClick(6)"><img src="../assets/img/W6.png" alt=""><div>查看工资</div></li>
                        <li @click="liClick(7)"><img src="../assets/img/W7.png" alt=""><div>职称指南</div></li>
                        <li @click="liClick(8)"><img src="../assets/img/W8.png" alt=""><div>业务导航</div></li>
                        <li @click="liClick(9)"><img src="../assets/img/W9.png" alt=""><div>我的审批</div></li>
                        <li @click="liClick(10)"><img src="../assets/img/W10.png" alt=""><div>学习政策</div></li>
                        <li @click="liClick(11)"><img src="../assets/img/W11.png" alt=""><div>上传线索</div></li>
                        <li @click="liClick(12)"><img src="../assets/img/W12.png" alt=""><div>上传文件</div></li>
                        <li @click="liClick(13)"><img src="../assets/img/W13.png" alt=""><div>转账</div></li>
                        <li @click="liClick(14)"><img src="../assets/img/W14.png" alt=""><div>核对账单</div></li>
                    </ul>
                </div>
            </div>
            <div class="FunctionNavCet dkdkdkd">
                <div id="myChart3"></div>

            </div>
            <div class="FunctionNavRight">
                <div>年度累计统计</div>
                <div>¥ {{tongji_year.amount}}</div>
                <div>2021年度销售计划{{tongji_year.assign}}万元人民币</div>
                <div>月度完成比   {{tongji_moth.ratio}}%</div>
                <div>月环比增长   {{tongji_moth.points}}%</div>
                <div id="myChart2"></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import * as echarts from 'echarts'
export default {
    name: "dashboard",
    data() { 
        return {
            Profile:{},
            Index_data:{},
            paylist:{},
            today:{},
            tongji_year:{},
            tongji_moth:{},
            total:{},
            noticelist:[],  
            Sale_list:[],
            JS:'',
            jieri:'',
            jieri2:'',
            time:'',
            time2:'',
            name: localStorage.getItem("ms_username")
        };
    },
    computed: {
        role() {
            return this.name === "admin" ? "超级管理员" : "普通用户";
        }
    },
    mounted() {
        var that = this
        this.getIndex()
        that.getList()
        that.getChart2()
        that.getChart1()
        setTimeout(function() {
           that.getChart3()
        },600)
    },
    methods: {
        getIndex(){  // 工作台
            var that = this
            var time = 0
            axios.get('/admin/dashboard/index',{   // 工作台
            }).then(function (res) {
                if (res.code == 404 || res.code == 401) {
                    that.$router.push('/');
                } else{
                    that.Index_data = res.data.data
                    that.paylist = res.data.data.paylist
                    that.today = res.data.data.today
                    that.tongji_year = res.data.data.tongji.year
                    that.tongji_moth = res.data.data.tongji.month
                    that.total = res.data.data.total
                    that.noticelist = res.data.data.noticelist

                    if (res.data.data.boliday.now.length > 0) {
                        that.jieri2 =that.Index_data.boliday.next.intro
                        time2 = res.data.data.boliday.next.endtime
                        that.countdowm(time2)
                        that.JS = 'jiari'
                    } else {
                        that.jieri =that.Index_data.boliday.next.intro
                        time = res.data.data.boliday.next.endtime
                        that.countdowm(time)
                         that.JS = ''
                    }
                }
            })
            axios.get('/admin/dashboard/goodsSale',{   // 商品销售
                params:{
                time: 'year'
                }
            }).then(function (res) {
                if (res.code == 0) {
                    that.$message.warning(res.data.msg);
                } else{ 
                    that.Sale_list = res.data.data.sale_list
                }
            })      
        },
        countdowm(timestamp){
            let self = this;
            let timer = setInterval(function(){
                let nowTime = new Date();
                let endTime = new Date(timestamp * 1000);
                let t = endTime.getTime() - nowTime.getTime();
                if(t>0){
                    let day = Math.floor(t/86400000);
                    let hour=Math.floor((t/3600000)%24);
                    let min=Math.floor((t/60000)%60);
                    let sec=Math.floor((t/1000)%60);
                    hour = hour < 10 ? "0" + hour : hour;
                    min = min < 10 ? "0" + min : min;
                    sec = sec < 10 ? "0" + sec : sec;
                    let format = '';
                    if(day > 0){
                       format =  `${day}天${hour}小时${min}分${sec}秒`;
                    }
                    if(day <= 0 && hour > 0 ){
                       format = `${hour}小时${min}分${sec}秒`;
                    }
                    if(day <= 0 && hour <= 0){
                       format =`${min}分${sec}秒`;
                    }
                    self.time = format;
                }else{
                  clearInterval(timer);
                  self.time = self.endText;
                  self._callback();
                }
            },1000);
        },
        liClick(e){
            if(e == 1){
                this.$router.push({ path: "/Workbench/SalesManagement",query:{isShow:2} });
            }else if(e == 2){
                this.$router.push({ path: "/Manage/MailList" });
            }else if(e == 3){
                this.$router.push({ path: "/Order/SecondKillOrder" });
            }else if(e == 4){
                this.$router.push({ path: "/Workbench/Family_A" ,query:{isShow:3}});
            }else if(e == 5){
                this.$router.push({ path: "/AfterSales/Aftershou_A" });
            }else if(e == 6){
                this.$router.push({ path: "/Workbench/Financial",query:{isShow:1}});
            }else if(e == 7){
                this.$router.push({ path: "/Business/Guides" });
            }else if(e == 8){
                this.$router.push({ path: "/Business/Busin_nav" });
            }else if(e == 9){
                this.$router.push({ path: "/Manage/Approve" });
            }else if(e == 10){
                this.$router.push({ path: "/Business/Guides" });
            }else if(e == 11){
                this.$router.push({ path: "/Workbench/SalesManagement" });
            }else if(e == 12){
                this.$router.push({ path: "/Workbench/Family_A" ,query:{isShow:2}});
            }else if(e == 13){
                this.$router.push({ path: "/Workbench/Financial",query:{isShow:3} });
            }else if(e == 14){
                this.$router.push({ path: "/Workbench/Financial" ,query:{isShow:2} });
            }else if(e == 15){
                this.$router.push({ path: "/Workbench/Family_A" });
            }
            
        },
        _callback(){
            if(this.callback && this.callback instanceof Function){
                this.callback(...this);
            }
        },
        getList(){  // 个人信息
            var that = this
            axios.get('/admin/general/profile/index',{
            }).then(function (res) {
                if (res.code == 404 || res.code == 401) {
                    that.$router.push('/Login');
                } else if (res.data.code == 0) {
                    alert('请求失败');  
                } else{ 
                    that.Profile = res.data.data.staff
                }
            })  
        },
        getChart3(){  // 销售额
            let myChart = echarts.init(document.getElementById('myChart3'))
            myChart.setOption({
                //     title: {
                //     text: '某站点用户访问来源',
                //     subtext: '纯属虚构',
                //     left: 'center'
                // },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                },
                graphic: [{ //环形图中间添加文字
                    type: 'text', //通过不同top值可以设置上下显示
                    left: 'center',
                    top: '44%',
                    style: {
                        text: '销售额'+'\n'+ '\n' + '151515',
                        textAlign: 'center',
                        fill: '#262626', //文字的颜色
                        width: 10,
                        height: 20,
                        fontSize: 20,
                        fontFamily: "Microsoft YaHei"
                    }
                }],
                series: [
                    {
                    name: 'Nightingale Chart',
                    type: 'pie',
                    radius: [50, 188],
                    center: ['50%', '50%'],
                    roseType: 'area',
                    itemStyle: {
                        borderRadius: 8
                    },
                    data: this.Sale_list
                    }
                ]
            })
        },
        getChart2(){  //年度累计统计
            let myChart = echarts.init(document.getElementById('myChart2'))
            myChart.setOption({
                xAxis: {
                    type: 'category',
                    show:false,
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    "splitLine": {"show": false}
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        lineStyle: {
                        color: ["#fff"],
                        },
                    },
                    show:false,
                    "splitLine": {"show": false}//网格线
                },
                series: [{
                    data: [820, 932, 901, 934, 1290, 1330, 1320],
                    type: 'line',
                    showSymbol: false,
                    hoverAnimation: false,
                    itemStyle:{
                        normal:{
                            lineStyle:{
                                color:'#1890FF' //折线颜色
                            }
                        }
                    }
                }]
            })
        },
        getChart1(){  //邮件营销
            let myChart = echarts.init(document.getElementById('myChart1'))
            myChart.setOption({
                title: { text: '' },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                legend: {
                    data: ['邮件营销', '联盟广告', '视频广告', '直接访问', '搜索引擎']
                },
                toolbox: {
                    feature: {saveAsImage: {}}
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                        splitLine:{show: true}
                    }
                ],
                yAxis: {
                    type: 'value',
                    //隐藏网格线
                    splitLine:{show: true}
                },
                series: [
                    {
                        name: '邮件营销',
                        type: 'line',
                        stack: '总量',
                        areaStyle: {},
                        emphasis: {
                            focus: 'series'
                        },
                        data: [120, 132, 101, 134, 90, 230, 210],
                        smooth: true
                    },

                    {
                        name: '直接访问',
                        type: 'line',
                        stack: '总量',
                        areaStyle: {},
                        emphasis: {
                            focus: 'series'
                        },
                        data: [320, 332, 301, 334, 390, 330, 320],
                        smooth: true
                    },
                ]
            })
        }

    }
};
</script>

<style scoped>
.headNav{
    display: flex;
    align-items: center;
    color: #8C8C8C;
    font-size: 14px;
}
.headNavLeft{
    width: 50%;
    display: flex;
    align-items: center;
    
}
.left{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 54px ;
    margin-right: 30px;
}
.right div:nth-child(1){
    color: #262626;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 8px;
}
.headNavRight div:nth-child(2){
    color: #262626;
    margin-top: 9px;
    font-size: 16px;
    font-weight: 700;
}
.saleNav{
    margin: 20px 20px;
    display: flex;
    justify-content: space-between;
}
.navs{
    min-width: 21%;
    height: 110px;
    background-color: #fff;
    border-radius: 6px;
    padding: 1.5%;
}
.navsTop{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
}
.navsTopLeft div:nth-child(2){
    font-size: 28px;
    margin: 15px 0;
}
.navsTopRight{
    width: 50px;
    height: 50px;
}
.navsBtn{
    margin-top: 15px;
}
.NoticeNav{
    display: flex;
    justify-content: space-between;
    min-height: 266px;
    margin: 0 20px;
}
.NoticeNavLeft{
    background-color: #fff;
    width: 68%;
    border-radius: 6px;
}
.NoticeNavRightTop{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    font-family: PingFang SC;
    cursor: pointer;
}
.NoticeNavRightTop div:nth-child(2){
    font-size: 14px;
    color: #1890FF;
}
.NoticeNavRight{
    width: 27.5%;
    border-radius: 6px;
    background-color: #fff;
    padding: 27px 1.5%;
}
.NoticeNavRightBtn{
    margin-top: 20px;
}
.NoticeNavRightBtn .span{
    width: 1px;
    height: 1px;
    border-radius: 50%;
    border: 2px solid #1890FF;
    margin-right: 15px;
}
.NoticeNavRightBtn div{
    height: 32px;
    line-height: 32px;
    color: #262626;
    list-style: disc;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.FunctionNav{
    display: flex;
    justify-content: space-between;
    min-height: 460px;
    margin: 24px 20px;
}
.FunctionNavLeft,
.FunctionNavCet,
.FunctionNavRight{
    padding: 1.5%;
    border-radius: 6px;
    background-color: #fff;
}
.FunctionNavLeft{
    width: 22.8%;
    padding: 24px 0;
}
.FunctionNavCet{
    width: 40.8%;
}
.FunctionNavRight{
    width: 27.5%;
}
.FunctionNavOne{
    font-size: 18px;
    font-weight: 700;
    padding: 0 24px;
}
.FunctionNavLi ul{
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
}
.FunctionNavLi li {
    width: 90px;
    height: 90px;
    text-align: center;
}
.FunctionNavLi li img{
    width: 40px;
    height: 40px;
    border-radius: 4px;
    margin-top: 26px;
}
.FunctionNavLi li div{
    font-family: PingFang SC;
    font-weight: bold;
    margin-top: 3px;

}
.FunctionNavRight div:nth-child(1){
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
}
.FunctionNavRight div:nth-child(2){
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    margin: 16px 0 10px;
}  
.FunctionNavRight div:nth-child(3){
    font-family: PingFang SC;
    font-weight: 500;
    color: #595959;
} 
.FunctionNavRight div:nth-child(4){
    font-family: PingFang SC;
    font-weight: 500;
    color: #595959;
    margin: 36px 0 14px;
} 
.FunctionNavRight div:nth-child(5){
    font-family: PingFang SC;
    font-weight: 500;
    color: #595959;
} 
#myChart2{
    width: 330px;
    height: 182px;
}
#myChart3 {
    width: 100%;
    height: 100%;
}
.dkdkdkd {
    width: 43.3%;
    padding: 1.5%;
    box-sizing: border-box;
}
#myChart1{
    width: 100%;
    height: 230px;
    float: left;
    margin-right: 50px;
    margin-top: 19px;
}
</style>
